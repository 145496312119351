<template>
  <div class='surveys-table'>
    <base-table
      v-if="logsBuyPlans && logsBuyPlansMeta"
      :columns='columns'
      :data='logsBuyPlans'
      :total-items='logsBuyPlansMeta.total'
      :start-items='logsBuyPlansMeta.skip + 1'
      :max-items='logsBuyPlansMeta.limit'
      @set-page='handleChangeUpdateLogs'
    >
      <template v-slot:body-userId='row'>
        {{ row.item && row.item.userId && row.item.userId.username ? row.item.userId.username : '-' }}
      </template>

      <template v-slot:body-action='row'>
        {{ row.item && row.item.action ? row.item.action : '-' }}
      </template>

      <template v-slot:body-sent='row'>
        <p class="whatTheSearchReturned">
          {{ row.item && row.item.sent ? row.item.sent : '-' }}
        </p>
      </template>

      <template v-slot:body-payloadResponse='row'>
        <p class="whatTheSearchReturned">
          {{ row.item && row.item.payloadResponse ? row.item.payloadResponse : '-' }}
        </p>
      </template>

      <template v-slot:body-payloadResponse='row'>
        <p class="whatTheSearchReturned">
          {{ row.item && row.item.payloadResponse ? row.item.payloadResponse : '-' }}
        </p>
      </template>

      <template v-slot:body-createdAt='row'>
        {{ formatDate(row.item.createdAt) }}
      </template>
    </base-table>
  </div>
</template>

<script>
import format from 'date-fns/esm/format/index'
import { mapActions } from 'vuex'

export default {
  name: 'PlanSubscriptionLogsTable',
  props: {
    logsBuyPlans: { type: Array, require: true },
    logsBuyPlansMeta: { type: Object, require: true }
  },
  async created() {
    console.log(this.logsBuyPlans)
  },
  data() {
    return {
      logUpdate: {},
      openDialog: false,
      columns: [
        { key: 'userId', value: 'Usuário' },
        { key: 'createdAt', value: 'Data' },
        { key: 'action', value: 'Log' },
        { key: 'status', value: 'status' },
        { key: 'sent', value: 'Enviado' },
        { key: 'payloadResponse', value: 'Recebido' },
      ]
    }
  },
  components: {
  },
  methods: {
    /*...mapActions('logApi', {
      handleGetLogsAPi: 'handleGetLogsAPi',
    }),*/
    async showToastResponse(message) {
      this.$bvToast.toast(message, {
        title: 'Aviso',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      await this.handleGetPartners()
      this.close()
    },
    async seeMoreUser(_id) {
      this.$emit('update-partner', _id)
    },
    formatDate(date) {
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },

    handleChangeUpdateLogs(page) {
      const pageCount = page <= 0 ? 1 : page
      const params = {
        skip: ((pageCount - 1) * this.logsBuyPlansMeta.limit)
      }
      this.$emit('change-logs', params)
    },

    /*show() {
      this.$refs['show-log'].show()
    },

    close() {
      this.$refs['show-log'].hide()
    }*/
  }
}
</script>

<style lang='scss' scoped>
.api-key {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.whatTheSearchReturned{
  text-align: justify;
}
.surveys-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  i {
    cursor: pointer;

    &:hover(:not(.fa-ban)) {
      opacity: 0.95;
    }
  }
}
</style>
