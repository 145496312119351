<template>
  <div>
    <b-overlay
      :show="loading"
      rounded
      no-center
      opacity="0.7"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block w-100"
    >
      <div class="inputs d-flex align-items-end justify-content-between mb-2">
        <base-input-text
          class='btn-data'
          block
          v-model='filter.startDate'
          type='date'
          label='Início'
        />

        <base-input-text
          block
          class='mx-1 btn-data'
          v-model='filter.endDate'
          type='date'
          label='Final'
        />

        <base-input-text
          block
          v-model="filter.filter"
          placeholder="Pesquisar"
          type="text"
        />

        <base-button
          class='btn-pesquisar ml-1'
          @click='applyFilters()'
        >
          Pesquisar
        </base-button>

        <base-button
          class='mx-1 btn-limpar-filtros'
          @click='resetFilters()'
        >
          Limpar filtros
        </base-button>
      </div>

      <plan-subscription-logs-table
        :logsBuyPlans='logsBuyPlans'
        :logsBuyPlansMeta='logsBuyPlansMeta'
        @change-logs='handleChangeLogsAPi'
      />
      <p class='text-center' v-if='logsBuyPlans.length < 1'>Nenhum item encontrado</p>

      <template v-slot:overlay>
        <div class="text-center loading-spinner">
          <b-spinner style="width: 3rem; height: 3rem;" variant='primary' label="Large Spinner"></b-spinner>
          <h5 class='text-primary'>Buscando...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import PlanSubscriptionLogsTable from '@/components/PlanSubscriptionLogs/Table'
import PlanSubscriptionLogsHeader from '@/components/PlanSubscriptionLogs/Header'
import { mapActions, mapGetters } from 'vuex'
import _ from 'underscore'

export default {
  name: 'PlanSubscriptionLogs',
  data() {
    return {
      logUpdate: {},
      loading: false,
      openDialog: false,
      openDialogUpdateApikeyPartner: false,
      openDialogModalSeeUpdateLogs: false,
      filter: {
        startDate: '',
        endDate: '',
        filter: '',
      },
      partnerId: '',
      message: ''
    }
  },
  components: {
    PlanSubscriptionLogsTable,
    PlanSubscriptionLogsHeader
  },
  computed: {
    ...mapGetters('subscriptionLogs', {
      logsBuyPlans: 'getLogsBuyPlans',
      logsBuyPlansMeta: 'getLogsBuyPlansMeta'
    }),
    exportLogs(){
      return `${process.env.VUE_APP_BASE_URL}/log-api/export/${this.startDate || 'not_selected'}/${this.endDate || 'not_selected'}/${this.filter || 'not_selected'}`
    }
  },
  watch: {
  },
  methods: {
    ...mapActions('subscriptionLogs', {
      handleGetLogsBuyPlans: 'handleGetLogsBuyPlans',
      resetLogsBuyPlans: 'resetLogsBuyPlans',
    }),
    async applyFilters(){
      await this.handleChangeLogsAPi(this.filter);
    },
    async resetFilters(){
      this.filter.filter = '';
      await this.resetLogsBuyPlans()
      await this.handleChangeLogsAPi()
    },
    async handleChangeLogsAPi(payload) {
      this.loading = true;
      const params = {
        ...this.logsBuyPlansMeta,
        ...payload
      }
      await this.handleGetLogsBuyPlans(params)
      this.loading = false;
    }
  },
  async created() {
    await this.handleChangeLogsAPi({sortIn: 'desc'})
  }
}
</script>

<style lang='scss' scoped>
.inputs {
  .btn-data {
    max-width: 155px;
  }

  .btn-pesquisar {
    min-width: 105px;
    height: 48px;
  }

  .btn-limpar-filtros {
    min-width: 135px;
    height: 48px;
  }

  .link-export {
    text-decoration: none;
  }

  .btn-export {
    min-width: 190px;
    height: 48px;
  }
}
.loading-spinner{
  margin-top: 250px;
}
</style>
